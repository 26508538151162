// @flow

import { BASE_URL_BACKEND, SCHEMA_DOMAIN, SCHEMA_NAME } from "../const/env";
import { ERROR_TYPE_CONNECTION, ERROR_TYPE_UNKNOWN } from "../const/errorType";
import type { AuthAkamaiRequest, ProfileChangePasswordRequest } from "./transfer/request";
import type {AuthStatusResponse, PartialResponse} from "./transfer/response";

type QueryParameters = { [string]: string };
type RequestBody = {...};

export class BackendApiError extends Error {
    code: number;
    errorType: string;

    constructor(statusCode: number, errorType: string, message: string) {
        super(message);

        this.code = statusCode;
        this.errorType = errorType;
    }
}

/**
 * Sends a request to the backend.
 * @throws {BackendApiError}
 */
async function sendRequest<T>(
    method: string,
    route: string,
    queryParameters?: QueryParameters,
    requestBody?: RequestBody,
): Promise<T> {
    const queryString = (new URLSearchParams(queryParameters)).toString();
    const requestUrl = BASE_URL_BACKEND + route + (queryString ? "?" + queryString : "");
    const requestOptions = buildRequestOptions(method, requestBody);

    let response;
    try {
        response = await fetch(requestUrl, requestOptions);
    } catch (e) {
        throw e;
    }

    return handleResponse(response);
}

/**
 * Builds the options used to send the request, including the serialized body if specified.
 */
function buildRequestOptions(method: string, requestBody?: RequestBody): RequestOptions {
    const headers: Headers = new Headers();
    const options: RequestOptions = {
        method: method,
        credentials: "include",
        headers: headers,
    };

    if (typeof requestBody === "object") {
        options.body = JSON.stringify(requestBody);
        headers.set("Content-Type", "application/json")
    }

    if (SCHEMA_DOMAIN && SCHEMA_NAME) {
        headers.set("Schema-Domain", SCHEMA_DOMAIN);
        headers.set("Schema-Name", SCHEMA_NAME);
    }

    return options;
}

/**
 * Handles the response received from the backend, decoding it as JSON if needed and checking its status.
 * @throws {BackendApiError}
 */
async function handleResponse<T>(response: Response): Promise<T> {
    let content: any = null;
    try {
        if (response.headers.get("Content-Type") === "application/json") {
            content = await response.json();
        }
    } catch (e) {
        throw new BackendApiError(500, ERROR_TYPE_CONNECTION, `Failed to parse response: ${e}`);
    }

    if (!response.ok) {
        handleFailedResponse(response.status, content);
    }

    return content;
}

/**
 * Handles a failed response, transforming it into and throwing a BackendApiError.
 * @throws {BackendApiError}
 */
function handleFailedResponse(statusCode: number, content: any): void {
    let message = content;
    let errorType = ERROR_TYPE_UNKNOWN;
    if (typeof content === "object") {
        message = content.error || "Unknown error";
        errorType = content.errorType || ERROR_TYPE_UNKNOWN;
    }

    throw new BackendApiError(statusCode, errorType, message);
}

export default {
    auth: {
        akamai: async (request: AuthAkamaiRequest): Promise<void> => {
            return await sendRequest<void>("POST", "/auth/akamai", {}, request);
        },
        reset: async (): Promise<void> => {
            return await sendRequest<void>("POST", "/auth/reset");
        },
        status: async(): Promise<AuthStatusResponse> => {
            return await sendRequest<AuthStatusResponse>("POST", "/auth/status");
        },
    },

    partial: {
        akamai: async (): Promise<PartialResponse> => {
            return await sendRequest<PartialResponse>("GET", "/partial/akamai");
        },
    },
    
    profile: {
        changePassword: async (request: ProfileChangePasswordRequest): Promise<void> => {
            return await sendRequest<void>("PUT", "/profile/change-password", {}, request);
        },
    },
};