// @flow

import { createSlice } from "@reduxjs/toolkit";
import api from "../backend/api";
import type { Dispatch, GlobalState } from "./types";

export type PasswordState = "" | "processing" | "success" | "error";
export type State = {
    isLoggedIn: boolean,
    passwordState: PasswordState,
};

type ChangedPasswordAction = {
    type: string,
    payload: PasswordState,
}

const slice = createSlice<State>({
    name: "hackyPasswordChange",
    initialState: {
        isLoggedIn: false,
        passwordState: "",
    },
    reducers: {
        login: (state: State) => {
            state.isLoggedIn = true;
        },
        changingPassword: (state: State) => {
            state.passwordState = "processing";
        },
        changedPassword: (state: State, action: ChangedPasswordAction) => {
            state.passwordState = action.payload;
        },
    }
});

export function login(accessToken: string) {
    return async (dispatch: Dispatch) => {
        try {
            await api.auth.akamai({ accessToken });
            dispatch(slice.actions.login());
        } catch(e) {
            console.log("LOGIN FAILED", e);
        }
    };
}

export function changePassword(currentPassword: string, newPassword: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.changingPassword());
        try {
            await api.profile.changePassword({ currentPassword, newPassword });
            dispatch(slice.actions.changedPassword("success"));
        } catch(e) {
            console.log("PASSWORD CHANGE FAILED", e);
            dispatch(slice.actions.changedPassword("error"));
        }
    }
}

export default slice.reducer;

export function selectState(state: GlobalState): State {
    return state.hackyPasswordChange;
}