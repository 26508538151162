import React from 'react';
import logo from './logo.png';
import Router from "./component/Router";
import {Link} from "react-router-dom";
import AkamaiWidget from "./component/AkamaiWidget";

import './App.css';

function App() {
    return (
        <>
            <AkamaiWidget />
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div>
                        Navigation: <Link to="/counter">Counter</Link> | <Link to="/hacky-password-change">Hacky Password Change</Link>
                    </div>
                </header>
                <hr />

                <Router />
            </div>
        </>
    );
}

export default App;
