// @flow

import { configureStore } from '@reduxjs/toolkit';
import akamaiWidgetReducer from "./akamaiWidget";
import authReducer from "./auth";
import counterReducer from './counter';
import hackyPasswordChangeReducer from "./hackyPasswordChange";

export default configureStore({
    reducer: {
        akamaiWidget: akamaiWidgetReducer,
        auth: authReducer,
        counter: counterReducer,
        hackyPasswordChange: hackyPasswordChangeReducer,
    },
});
