// @flow

export function openSignInScreen(): void {
    if (!window.janrain || !window.janrain.capture) {
        return;
    }

    window.janrain.capture.ui.renderScreen("signIn");
}

export function openRegisterScreen(): void {
    if (!window.janrain || !window.janrain.capture) {
        return;
    }

    window.janrain.capture.ui.renderScreen("traditionalRegistration");
}

export function logout(): void {
    if (!window.janrain || !window.janrain.capture) {
        return;
    }

    window.janrain.capture.ui.endCaptureSession();
}