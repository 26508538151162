// @flow

import React, {Component} from "react";
import {changePassword, selectState} from "../../redux/hackyPasswordChange";
import {connect} from "react-redux";
import type {PasswordState} from "../../redux/hackyPasswordChange";
import { selectFirstName, selectLoggedIn } from "../../redux/auth";
import { logout, openRegisterScreen, openSignInScreen } from "../../util/akamai";
import type { GlobalState } from "../../redux/types";

type Props = {
    isLoggedIn: boolean,
    firstName: string,
    passwordState: PasswordState,

    changePassword: typeof changePassword,
    logout: typeof logout,
}

type State = {
    currentPassword: string,
    newPassword: string,
};

class HackyPasswordChangePage extends Component<Props, State> {
    state: State = {
        currentPassword: "",
        newPassword: "",
    }

    handleCurrentPasswordChange(event: SyntheticInputEvent<HTMLInputElement>): void {
        this.setState({ currentPassword: event.target.value });
    }

    handleNewPasswordChange(event: SyntheticInputEvent<HTMLInputElement>): void {
        this.setState({ newPassword: event.target.value });
    }

    handleChangePasswordClick(): void {
        this.props.changePassword(this.state.currentPassword, this.state.newPassword);
    }
    
    render(): React$Node {
        if (!this.props.isLoggedIn) {
            return (
                <>
                    <input type="button" value="Login" onClick={openSignInScreen} />
                    <input type="button" value="Register" onClick={openRegisterScreen} />
                </>
            );
        }

        return (
            <div>
                Hello there, {this.props.firstName}!
                <input type="button" value="Logout" onClick={logout} />
                <br /><br />
                Current Password: <input type="password" value={this.state.currentPassword} onChange={this.handleCurrentPasswordChange.bind(this)}/><br />
                New Password: <input type="password" value={this.state.newPassword} onChange={this.handleNewPasswordChange.bind(this)} /><br />
                <input type="button" value="Change Password" onClick={this.handleChangePasswordClick.bind(this)} /><br />
                {this.props.passwordState === "processing" ? "Processing password..." : null}
                {this.props.passwordState === "success" ? "Changed successfully!" : null}
                {this.props.passwordState === "error" ? "Password change failed :(" : null}
            </div>
        );
    }
}

function mapState(state: GlobalState): $Shape<Props> {
    const s = selectState(state);

    return {
        isLoggedIn: selectLoggedIn(state),
        firstName: selectFirstName(state),

        passwordState: s.passwordState,
    }
}

export default connect(mapState, { changePassword })(HackyPasswordChangePage);