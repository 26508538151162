// @flow

import { createSlice } from "@reduxjs/toolkit";
import type { AuthStatusResponse } from "../backend/transfer/response";
import type { Dispatch } from "./types";
import api from "../backend/api";

export type State = {
    isLoggedIn: boolean,
    firstName: string,
};

type StatusAction = {
    type: string,
    payload: AuthStatusResponse,
};

const slice = createSlice<State>({
    name: "auth",
    initialState: {
        isLoggedIn: false,
        firstName: "",
    },
    reducers: {
        status: (state: State, action: StatusAction) => {
            state.isLoggedIn = action.payload.isLoggedIn;
            state.firstName = action.payload.firstName || "";
        },
    }
});

export function requestAuthStatus() {
    return async (dispatch: Dispatch) => {
        try {
            const authStatus = await api.auth.status();
            dispatch(slice.actions.status(authStatus));
        } catch (e) {
            console.log("ERROR AUTH STATUS", e);
        }
    };
}

export function login(akamaiAccessToken: string) {
    return async (dispatch: Dispatch) => {
        await api.auth.akamai({ accessToken: akamaiAccessToken });
        const authStatus = await api.auth.status();
        dispatch(slice.actions.status(authStatus));
    };
}

export function logout() {
    return async(dispatch: Dispatch) => {
        await api.auth.reset();
        const authStatus = await api.auth.status();
        dispatch(slice.actions.status(authStatus));
    }
}

export default slice.reducer;

export function selectLoggedIn(state: any): boolean {
    return state.auth.isLoggedIn;
}

export function selectFirstName(state: any): string {
    return state.auth.firstName;
}
