// @flow

import React from "react";
import { Route, Switch } from "react-router-dom";
import {Counter} from "./page/Counter";
import HackyPasswordChangePage from "./page/HackyPasswordChangePage";

const Router = (): React$Node => {
    return (
        <Switch>
            <Route exact path="/counter" component={Counter} />
            <Route exact path="/hacky-password-change" component={HackyPasswordChangePage} />
        </Switch>
    );
};

export default Router;