// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import { loadWidget, selectAkamaiWidget } from "../../redux/akamaiWidget";
import { login, logout, requestAuthStatus } from "../../redux/auth";
import type { GlobalState } from "../../redux/types";

type Props = {
    markup: string,
    scriptUrl: string,
    stylesheetUrl: string,

    loadWidget: typeof loadWidget,
    login: typeof login,
    logout: typeof logout,
    requestAuthStatus: typeof requestAuthStatus,
};

class AkamaiWidget extends Component<Props> {
    componentDidMount(): void {
        window.janrainCaptureWidgetOnInit = this.handleWidgetInit.bind(this);

        this.props.requestAuthStatus();
        this.props.loadWidget();
    }

    handleWidgetInit(): void {
        window.janrain.events.onCaptureLoginSuccess.addHandler((event) => {
            // This handler may move directly into the widget for auto-logging in from the portal etc.
            this.props.login(event.accessToken);
        });
        window.janrain.events.onCaptureSessionEnded.addHandler(() => {
            // This handler may move directly into the widget for logging out from the portal.
            this.props.logout();
        });
    }

    componentDidUpdate(prevProps: Props): void {
        if (prevProps.scriptUrl === "" && this.props.scriptUrl !== "") {
            this.injectScript(this.props.scriptUrl);
        }
        if (prevProps.stylesheetUrl === "" && this.props.stylesheetUrl !== "") {
            this.injectStylesheet(this.props.stylesheetUrl);
        }
    }

    injectScript(scriptUrl: string): void {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = scriptUrl;
        this.appendNodeToHead(script);
    }
    
    injectStylesheet(stylesheetUrl: string): void {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = stylesheetUrl;
        link.media = "all";
        this.appendNodeToHead(link);
    }

    appendNodeToHead(node: Node): void {
        if (document.head) {
            document.head.appendChild(node);
        }
    }

    render(): React$Node {
        if (!this.props.markup) {
            return null;
        }

        return <div dangerouslySetInnerHTML={{ __html: this.props.markup }} />
    }
}

function mapState(state: GlobalState): $Shape<Props> {
    const akamaiWidgetState = selectAkamaiWidget(state);
    return {
        markup: akamaiWidgetState.markup,
        scriptUrl: akamaiWidgetState.scriptUrl,
        stylesheetUrl: akamaiWidgetState.stylesheetUrl,
    }
}

export default connect(mapState, { loadWidget, login, logout, requestAuthStatus })(AkamaiWidget);