// @flow

import { createSlice } from "@reduxjs/toolkit";
import type { PartialResponse } from "../backend/transfer/response";
import type { Dispatch, GlobalState } from "./types";
import api from "../backend/api";

export type State = {
    markup: string,
    scriptUrl: string,
    stylesheetUrl: string,
};

type InitAction = {
    type: string,
    payload: PartialResponse,
};

const slice = createSlice<State>({
    name: "akamaiWidget",
    initialState: {
        markup: "",
        scriptUrl: "",
        stylesheetUrl: "",
    },
    reducers: {
        init: (state: State, action: InitAction) => {
            state.markup = action.payload.markup;
            state.scriptUrl = action.payload.scriptUrl;
            state.stylesheetUrl = action.payload.stylesheetUrl;
        },
    },
});

export function loadWidget() {
    return async (dispatch: Dispatch) => {
        try {
            const partial = await api.partial.akamai();
            dispatch(slice.actions.init(partial));
        } catch (e) {
            console.log("fetching akamai assets failed", e);
        }
    };
}

export default slice.reducer;

export function selectAkamaiWidget(state: GlobalState): State {
    return state.akamaiWidget;
}
